<template>
  <div class="container" id="content">
    <TableBox
      ref="tableBox"
      :tableHeader="tableHeader"
      :rowBtns="rowBtns"
      request="/sys/uriToken/page"
      @addOption="addOption"
    ></TableBox>

    <UpdataModal
      ref="updata"
      :showFlag="showFlag"
      :messageBox="messageBox"
      :formTitle="formTitle"
      @close="closeModal"
      @success="refresh"
      :record="addForm"
    ></UpdataModal>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";
import TableBox from "@/components/lxw/TableBox";

export default {
  name: "lawsAReg",
  components: { UpdataModal, TableBox },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},

      tableHeader: [
        {
          label: "账号",
          key: "username",
          type: "Input",
        },
        {
          label: "token",
          key: "token",
          type: "Input",
        },
        {
          label: "每日调用次数",
          key: "dycs",
          type: "InputNumber",
        },
        {
          label: "到期日期",
          key: "dqrq",
          type: "DateTime",
        },
        {
          label: "传感器设备",
          key: "deviceNums",
          type: "Input",
        },
        {
          label: "授权接口",
          key: "uris",
          type: "Input",
        },
       
      ],
      rowBtns: [
        {
          label: "编辑",
          type: "primary",
          size: "small",
          icon: "el-icon-edit",
          handler: (row) => {
            this.edits(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      token: "",
      filesList: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/sys/uriToken/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.$refs.tableBox.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;

      this.$nextTick(() => {
        this.addForm = {
        };
        this.$refs.updata.resetForm();
      });
    },

    refresh() {
      this.messageBox = false;
      this.$refs.tableBox.getList();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style scoped>
</style>
